/*
	import { erasePhysicalMeeting } from '@/services/BoardMeetings/PhysicalMeeting/Delete';

  const { successful } = await erasePhoneMeeting ({
    physicalMeetingID
  });
*/

/*

*/

import kms from '@/services/kms'
import { notifyError } from '@/services/notify'

export async function erasePhysicalMeeting({ physicalMeetingID }) {
  let exception = ''

  try {
    const result = await kms.delete(`/BoardMeetings/PhysicalMeeting/${physicalMeetingID}`)

    return {
      successful: true,
      message: 'The physical meeting was deleted successfully.',
      exception: null,
      phoneMeeting: result
    }
  } catch (_exception) {
    exception = _exception
  }

  let message = 'A problem occurred and the physical meeting could not be deleted.'
  notifyError(message)

  return {
    successful: false,
    message,
    exception,
    phoneMeeting: null
  }
}

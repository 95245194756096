/*
	import { updatePhysicalMeeting } from '@/services/BoardMeetings/PhysicalMeeting/Put';

  const { updatePhysicalMeeting } = await updatePhysicalMeeting ({
    json: {
			...
    }
  });
*/

import kms from '@/services/kms'
import { notifyError } from '@/services/notify'

export async function updatePhysicalMeeting({ json }) {
  let exception = ''

  try {
    const result = await kms.put(`/BoardMeetings/PhysicalMeeting`, json)

    return {
      successful: true,
      message: 'The physical meeting was updated successfully.',
      exception: null,
      physicalMeeting: result
    }
  } catch (_exception) {
    exception = _exception
  }

  let message = 'The physical meeting could not be updated.'
  notifyError(message)

  return {
    successful: false,
    message,
    exception,
    physicalMeeting: null
  }
}

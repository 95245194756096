/*
	import { listPhoneMeetings } from '@/services/BoardMeetings/PhoneMeeting/List';

  const { phoneMeetings } = await listPhoneMeetings ({
    params: {
			hoaID
    }
  });
*/

import _get from 'lodash/get'
import kms from '@/services/kms'
import { notifyError } from '@/services/notify'

export async function listPhoneMeetings({ params }) {
  let message = 'The phone meetings list could not be retrieved.'

  try {
    const result = await kms.get(`/BoardMeetings/PhoneMeeting/List`, {
      params: {
        hoaID: params.hoaID
      }
    })

    let list = _get(result, 'results', [])
    if (!Array.isArray(list)) {
      list = []
    }

    return {
      successful: true,
      message: '',
      phoneMeetings: list
    }
  } catch (exception) {
    console.error(exception)
  }

  notifyError(message)

  return {
    successful: false,
    message,
    phoneMeetings: []
  }
}

<template>
  <div :style="{ display: 'flex', width: '100%', justifyContent: 'space-between' }">
    <Card :style="{ flexBasis: '50%', padding: '4px' }">
      <div :style="{ color: color[8] }">Phone Number</div>
      <div :style="{ color: color[8], fontWeight: 'bold' }">{{ phoneNumber }}</div>
    </Card>
    <Card :style="{ flexBasis: '50%', padding: '4px', marginLeft: '8px' }">
      <div :style="{ color: color[8] }">Access Code</div>
      <div :style="{ color: color[8], fontWeight: 'bold' }">{{ accessCode }}</div>
    </Card>
  </div>
</template>

<script>
import _get from 'lodash/get'

import Card from '@/components/Card'

export default {
  components: {
    Card
  },
  props: {
    phoneNumber: String,
    accessCode: String
  },
  data() {
    const theme = _get(this, ['$store', 'state', 'theme'], {})

    return {
      color: theme.color
    }
  }
}
</script>

/*
	import { addPhoneMeeting } from '@/services/BoardMeetings/PhoneMeeting/Post';

  const { phoneMeeting } = await addPhoneMeeting ({
    json: {
			hoaID,
      
      phoneNumber,
      
      // optional
      accessCode
    }
  });
*/

/*
  exception.fields {
    phoneNumber: [ '' ],    
  }
*/

import kms from '@/services/kms'
import { notifyError } from '@/services/notify'

export async function addPhoneMeeting({ json }) {
  let exception = ''

  try {
    const result = await kms.post(`/BoardMeetings/PhoneMeeting`, json)

    return {
      successful: true,
      message: 'The phone meeting was added successfully.',
      exception: null,
      phoneMeeting: result
    }
  } catch (_exception) {
    exception = _exception
  }

  let message = 'The phone meeting could not be added.'
  notifyError(message)

  return {
    successful: false,
    message,
    exception,
    phoneMeeting: null
  }
}

<template>
  <div
    class="card"
    :style="{
      border: '1px solid #eee'
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {}
}
</script>

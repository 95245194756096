import { addPhoneMeeting } from '@/services/BoardMeetings/PhoneMeeting/Post'
import { addPhysicalMeeting } from '@/services/BoardMeetings/PhysicalMeeting/Post'
import { addVirtualMeeting } from '@/services/BoardMeetings/VirtualMeeting/Post'
import { updatePhoneMeeting } from '@/services/BoardMeetings/PhoneMeeting/Put'
import { updatePhysicalMeeting } from '@/services/BoardMeetings/PhysicalMeeting/Put'
import { updateVirtualMeeting } from '@/services/BoardMeetings/VirtualMeeting/Put'
import { notifyError, notifyMessage } from '@/services/notify'

export const methods = {
  async open() {
    this.loading = true
    this.toggle = true

    this.phone = {}
    this.virtual = {}
    this.physical = {}
    this.note = ''
    this.loading = false

    this.formData = {
      foreignAddress: false,
      address1: '',
      address2: '',
      address3: '',
      addressID: 0,
      city: '',
      region: '',
      postalCode: ''
    }

    this.genericMeetingID = 0

    this.foreignAddress = false

    this.title = 'Add'
  },

  async edit(meeting) {
    this.loading = true
    this.toggle = true

    this.phone = {}
    this.virtual = {}
    this.physical = {}
    this.note = ''
    this.loading = false

    this.formData = {
      foreignAddress: false,
      address1: '',
      address2: '',
      address3: '',
      city: '',
      region: '',
      postalCode: ''
    }

    this.foreignAddress = false

    if (meeting !== undefined && meeting) {
      console.debug('edit meeting...' + JSON.stringify(meeting))

      this.title = 'Update'

      if (meeting.locationType === 'Phone') {
        this.meetingType = 'Phone'
        this.phone.phoneNumber = meeting.phoneNumber
        this.phone.accessCode = meeting.accessCode
        this.phone.meetingLink = meeting.meetingLink
        this.note = meeting.note
        this.genericMeetingID = meeting.phoneMeetingID
      } else if (meeting.locationType === 'Physical') {
        this.meetingType = 'Physical'
        this.physical.locationName = meeting.locationName
        this.formData.foreignAddress = meeting.foreignAddress
        this.formData.address1 = meeting.address1
        this.formData.address2 = meeting.address2
        this.formData.addressID = meeting.addressID
        this.genericMeetingID = meeting.physicalMeetingID

        if (meeting.foreignAddress === true) {
          this.formData.foreignAddress = true
          this.foreignAddress = true
          this.formData.address3 = meeting.address3
          this.formData.city = ''
          this.formData.region = ''
          this.formData.postalCode = ''
        } else {
          this.formData.foreignAddress = false
          this.foreignAddress = false
          this.formData.address3 = ''
          this.formData.city = meeting.city
          this.formData.region = meeting.region
          this.formData.postalCode = meeting.postalCode
        }

        this.note = meeting.note
      } else if (meeting.locationType === 'Virtual') {
        this.meetingType = 'Virtual'
        this.virtual.meetingLink = meeting.meetingLink
        this.virtual.accessCode = meeting.accessCode
        this.virtual.password = meeting.password
        this.note = meeting.note
        this.genericMeetingID = meeting.virtualMeetingID
      }
    }
  },

  close() {
    this.toggle = false
  },

  async save() {
    const meetingType = this.meetingType
    this.loading = true

    try {
      if (meetingType === 'Phone') {
        await this.addPhoneMeeting()
      } else if (meetingType === 'Virtual') {
        await this.addVirtualMeeting()
      } else if (meetingType === 'Physical') {
        await this.addPhysicalMeeting()
      }
    } catch (x) {
      console.debug(x)
    }

    this.loading = false
    this.toggle = false
  },

  async saveupdate() {
    const meetingType = this.meetingType
    this.loading = true

    try {
      if (meetingType === 'Phone') {
        await this.updatePhoneMeeting()
      } else if (meetingType === 'Virtual') {
        await this.updateVirtualMeeting()
      } else if (meetingType === 'Physical') {
        await this.updatePhysicalMeeting()
      }
    } catch (x) {
      console.debug(x)
    }

    this.loading = false
    this.toggle = false
  },

  async addPhoneMeeting() {
    const { phoneNumber, accessCode } = this.phone

    const { exception, message } = await addPhoneMeeting({
      json: {
        hoaID: this.hoaId,

        phoneNumber,
        accessCode
      }
    })
    if (exception) {
      console.error(exception)
      this.$refs.form.setErrors(exception.fields)
      return
    }

    notifyMessage(message)
    this.update()
  },

  async updatePhoneMeeting() {
    const { phoneNumber, accessCode } = this.phone

    const { exception, message } = await updatePhoneMeeting({
      json: {
        hoaID: this.hoaId,
        phoneMeetingID: this.genericMeetingID,
        phoneNumber,
        accessCode,
        note: this.note
      }
    })
    if (exception) {
      console.error(exception)
      this.$refs.form.setErrors(exception.fields)
      return
    }

    notifyMessage(message)
    this.update()
  },

  async addVirtualMeeting() {
    const { meetingLink, password, accessCode } = this.virtual

    const { exception, message } = await addVirtualMeeting({
      json: {
        hoaID: this.hoaId,

        meetingLink,
        accessCode,
        password
      }
    })
    if (exception) {
      console.error(exception)
      this.$refs.form.setErrors(exception.fields)
      return
    }

    notifyMessage(message)
    this.update()
  },

  async updateVirtualMeeting() {
    const { meetingLink, password, accessCode } = this.virtual

    const { exception, message } = await updateVirtualMeeting({
      json: {
        hoaID: this.hoaId,
        virtualMeetingID: this.genericMeetingID,
        meetingLink,
        accessCode,
        password,
        note: this.note
      }
    })
    if (exception) {
      console.error(exception)
      this.$refs.form.setErrors(exception.fields)
      return
    }

    notifyMessage(message)
    this.update()
  },

  async addPhysicalMeeting() {
    if (this.formData === undefined || !this.formData) {
      notifyError('There was a problem adding this meeting.')
      return
    }

    const { locationName } = this.physical

    const { exception, message } = await addPhysicalMeeting({
      json: {
        hoaID: this.hoaId,
        addressID: 0,
        locationName: locationName ?? 'New Location',
        address1: this.formData.address1,
        address2: this.formData.address2,
        address3: this.formData.address3,
        city: this.formData.city,
        region: this.formData.region,
        postalCode: this.formData.postalCode,
        foreignAddress: this.formData.foreignAddress,
        note: this.note
      }
    })
    if (exception) {
      console.error(exception)
      this.$refs.form.setErrors(exception.fields)
      return
    }

    notifyMessage(message)
    this.update()
  },

  async updatePhysicalMeeting() {
    if (this.formData === undefined || !this.formData) {
      notifyError('There was a problem updating this meeting.')
      return
    }

    console.debug('address1=' + this.formData.address1)

    const { exception, message } = await updatePhysicalMeeting({
      json: {
        hoaID: this.hoaId,
        physicalMeetingID: this.genericMeetingID,
        addressID: this.formData.addressID,
        locationName: this.physical.locationName || 'Update Location',
        address1: this.formData.address1,
        address2: this.formData.address2,
        address3: this.formData.address3,
        city: this.formData.city,
        region: this.formData.region,
        postalCode: this.formData.postalCode,
        foreignAddress: this.formData.foreignAddress,
        note: this.note
      }
    })
    if (exception) {
      console.error(exception)
      this.$refs.form.setErrors(exception.fields)
      return
    }

    notifyMessage(message)
    this.update()
  }
}

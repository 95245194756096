<template>
  <div :style="{ display: 'flex', width: '100%', justifyContent: 'space-between' }">
    <Card :style="{ flexBasis: '50%', padding: '8px' }">
      <div :style="{ color: color[8] }">Location Name</div>
      <div :style="{ color: color[8], fontWeight: 'bold' }">{{ locationName }}</div>
    </Card>
    <Card :style="{ flexBasis: '50%', marginLeft: '8px', padding: '8px' }">
      <div :style="{ color: color[8] }">Address</div>
      <div :style="{ color: color[8], fontWeight: 'bold' }">{{ address }}</div>
    </Card>
  </div>
</template>

<script>
import _get from 'lodash/get'
import { mapState } from 'vuex'
import { getUnitList } from '@/services/Roster/Unit/List'
import parseAddress from '@/utilities/address/parse'
import Card from '@/components/Card'

export default {
  components: {
    Card
  },
  props: {
    locationName: String,
    addressID: [String, Number],
    address1: String,
    address2: String,
    address3: String,
    city: String,
    region: String,
    postalCode: String,
    foreignAddress: Boolean
  },
  data() {
    const theme = _get(this, ['$store', 'state', 'theme'], {})

    return {
      color: theme.color,
      address: ''
    }
  },
  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },
  methods: {
    async getAddress() {
      if (this.address1) {
        var address = {
          addressOne: this.address1,
          addressTwo: this.address2,
          foreignAddress: this.foreignAddress,
          addressThree: this.address3,
          city: this.city,
          state: this.region,
          postalCode: this.postalCode
        }

        this.address = parseAddress({
          address
        })
      } else {
        const { list, exception } = await getUnitList({
          hoaID: this.hoaId
        })
        if (exception) {
          console.error(exception)
          return
        }

        for (let a = 0; a < list.length; a++) {
          const address = _get(list, [a, 'address'], '')
          const addressID = _get(address, ['addressID'], '')

          if (addressID === this.addressID) {
            this.address = parseAddress({
              address
            })
            return
          }
        }
      }
    }
  },
  mounted() {
    this.getAddress()
  }
}
</script>

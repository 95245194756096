/*
	import { updateVirtualMeeting } from '@/services/BoardMeetings/VirtualMeeting/Put';

  const { updateVirtualMeeting } = await updateVirtualMeeting ({
    json: {
			hoaID,
       virtualMeetingID,
      meetingLink: "link.www",
      accessCode: "1",
      password: "0",
      note: ""
    }
  });
*/

/*
  exception.fields {
    : [ '' ],    
  }
*/

import kms from '@/services/kms'
import { notifyError } from '@/services/notify'

export async function updateVirtualMeeting({ json }) {
  let exception = ''

  try {
    const result = await kms.put(`/BoardMeetings/VirtualMeeting`, json)

    return {
      successful: true,
      message: 'The virtual meeting was updated successfully.',
      exception: null,
      virtualMeeting: result
    }
  } catch (_exception) {
    exception = _exception
  }

  let message = 'The virtual meeting could not be updated.'
  notifyError(message)

  return {
    successful: false,
    message,
    exception,
    virtualMeeting: null
  }
}

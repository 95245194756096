<template>
  <div id="board-meeting-locations-list">
    <b-button @click="$refs.locationModal.open()" type="is-primary is-rounded"
      >Add Location</b-button
    >

    <div :style="{ padding: '10px 0' }" />

    <ModernTable
      tests-id="meetings locations"
      :columns="columns"
      :rows="rows"
      :filters="{
        show: true
      }"
    />

    <LocationModal ref="locationModal" :update="locationsUpdated" />

    <b-loading :is-full-page="false" v-model="loading" :can-cancel="false" />
  </div>
</template>

<script>
import LocationModal from './components/LocationModal'
import ModernTable from '@/components/tables/Modern/Table.vue'

import { computed } from './parts/computed'

import { data } from './parts/data'
import { methods } from './parts/methods'

export default {
  components: {
    LocationModal,
    ModernTable
  },

  computed,
  data,
  methods,

  mounted() {
    this.refresh()
  }
}
</script>

<style lang="scss">
#board-meeting-locations-list {
  tr td .mobile-label {
    width: 20%;
  }
}
</style>

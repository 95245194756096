import { listPhoneMeetings } from '@/services/BoardMeetings/PhoneMeeting/List'
import { listPhysicalMeetings } from '@/services/BoardMeetings/PhysicalMeeting/List'
import { listVirtualMeetings } from '@/services/BoardMeetings/VirtualMeeting/List'
import { erasePhoneMeeting } from '@/services/BoardMeetings/PhoneMeeting/Delete'
import { erasePhysicalMeeting } from '@/services/BoardMeetings/PhysicalMeeting/Delete'
import { eraseVirtualMeeting } from '@/services/BoardMeetings/VirtualMeeting/Delete'
import PhoneMeetingDetails from '../components/PhoneMeetingDetails'
import PhysicalMeetingDetails from '../components/PhysicalMeetingDetails'
import VirtualMeetingDetails from '../components/VirtualMeetingDetails'
import BuefyButton from '@/components/buttons/Buefy'
import Button from '@/components/buttons/Button'
import _get from 'lodash/get'

export const methods = {
  async locationsUpdated() {
    this.loading = true
    this.$refs.locationModal.close()

    this.refresh()
  },
  async refresh() {
    this.loading = true

    await this.getMeetings()

    this.loading = false
  },

  processLocation(phoneMeetingID, physicalMeetingID, virtualMeetingID, meeting) {
    meeting.locationType = ''
    if (phoneMeetingID !== undefined && phoneMeetingID > 0) {
      console.debug('in phoneMeetingID=' + phoneMeetingID)
      meeting.locationType = 'Phone'
    } else if (physicalMeetingID !== undefined && physicalMeetingID > 0) {
      console.debug('in physicalMeetingID=' + physicalMeetingID)
      meeting.locationType = 'Physical'
    } else if (virtualMeetingID !== undefined && virtualMeetingID > 0) {
      console.debug('in virtualMeetingID=' + virtualMeetingID)
      meeting.locationType = 'Virtual'
    }

    this.$refs.locationModal.edit(meeting)
  },

  async getMeetings() {
    const hoaID = this.hoaId
    const component = this

    const { phoneMeetings } = await listPhoneMeetings({
      params: {
        hoaID
      }
    })
    const { physicalMeetings } = await listPhysicalMeetings({
      params: {
        hoaID
      }
    })
    const { virtualMeetings } = await listVirtualMeetings({
      params: {
        hoaID
      }
    })

    this.rows = [
      ...phoneMeetings.map(meeting => {
        const phoneNumber = _get(meeting, 'phoneNumber', '')
        const accessCode = _get(meeting, 'accessCode', '')
        const phoneMeetingID = _get(meeting, 'phoneMeetingID', '')

        return {
          meetingType: 'phone',
          details: {
            component: PhoneMeetingDetails,
            props: {
              phoneNumber: phoneNumber,
              accessCode: accessCode
            }
          },
          erase: {
            component: BuefyButton,
            props: {
              click: function() {
                component.$buefy.dialog.confirm({
                  title: 'Phone Location Deletion',
                  message:
                    'Are you sure you would like to <b>Delete</b> this phone location as a meeting option? This action cannot be undone.',
                  confirmText: 'Delete Phone Location',
                  type: 'is-danger',
                  hasIcon: true,
                  onConfirm: async () => {
                    await erasePhoneMeeting({
                      phoneMeetingID
                    })

                    component.refresh()
                  }
                })
              },
              text: 'Delete',
              type: 'is-danger'
            }
          },
          edit: {
            component: Button,
            props: {
              onClick: () => {
                this.processLocation(phoneMeetingID, 0, 0, meeting)
              },
              text: 'Details'
            }
          }
        }
      }),
      ...physicalMeetings.map(meeting => {
        const locationName = _get(meeting, 'locationName', '')
        const addressID = _get(meeting, 'addressID', '')
        const address1 = _get(meeting, 'address1', '')
        const address2 = _get(meeting, 'address2', '')
        const address3 = _get(meeting, 'address3', '')
        const city = _get(meeting, 'city', '')
        const region = _get(meeting, 'region', '')
        const postalCode = _get(meeting, 'postalCode', '')
        const physicalMeetingID = _get(meeting, 'physicalMeetingID', '')
        const foreignAddress = _get(meeting, 'foreignAddress', false)

        return {
          meetingType: 'physical',
          details: {
            component: PhysicalMeetingDetails,
            props: {
              locationName,
              addressID,
              address1,
              address2,
              address3,
              city,
              region,
              postalCode,
              foreignAddress
            }
          },
          erase: {
            component: BuefyButton,
            props: {
              click: function() {
                component.$buefy.dialog.confirm({
                  title: 'Physical Location Deletion',
                  message:
                    'Are you sure you would like to <b>Delete</b> this physical location as a meeting option? This action cannot be undone.',
                  confirmText: 'Delete Physical Location',
                  type: 'is-danger',
                  hasIcon: true,
                  onConfirm: async () => {
                    await erasePhysicalMeeting({
                      physicalMeetingID
                    })

                    component.refresh()
                  }
                })
              },
              text: 'Delete',
              type: 'is-danger'
            }
          },
          edit: {
            component: Button,
            props: {
              onClick: () => {
                this.processLocation(0, physicalMeetingID, 0, meeting)
              },
              text: 'Details'
            }
          }
        }
      }),
      ...virtualMeetings.map(meeting => {
        const virtualMeetingID = _get(meeting, 'virtualMeetingID', '')

        const meetingLink = _get(meeting, 'meetingLink', '')
        const accessCode = _get(meeting, 'accessCode', '')
        const password = _get(meeting, 'password', '')

        return {
          meetingType: 'virtual',
          details: {
            component: VirtualMeetingDetails,
            props: {
              meetingLink,
              accessCode,
              password
            }
          },
          erase: {
            component: BuefyButton,
            props: {
              click: function() {
                component.promptErase({ meeting })

                component.$buefy.dialog.confirm({
                  title: 'Virtual Location Deletion',
                  message:
                    'Are you sure you would like to <b>Delete</b> this virtual location as a meeting option? This action cannot be undone.',
                  confirmText: 'Delete Virtual Location',
                  type: 'is-danger',
                  hasIcon: true,
                  onConfirm: async () => {
                    await eraseVirtualMeeting({
                      virtualMeetingID
                    })

                    component.refresh()
                  }
                })
              },
              text: 'Delete',
              type: 'is-danger'
            }
          },
          edit: {
            component: Button,
            props: {
              onClick: () => {
                this.processLocation(0, 0, virtualMeetingID, meeting)
              },
              text: 'Details'
            }
          }
        }
      })
    ]
  },
  promptErase() {}
}

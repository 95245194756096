<template>
  <div :style="{ display: 'flex', width: '100%', justifyContent: 'space-between' }">
    <Card :style="{ flexBasis: '30%', padding: '8px' }">
      <div :style="{ color: color[8] }">Meeting Link</div>
      <div :style="{ color: color[8], fontWeight: 'bold' }">{{ meetingLink }}</div>
    </Card>
    <Card :style="{ flexBasis: '30%', padding: '8px', marginLeft: '4px' }">
      <div :style="{ color: color[8] }">Access Code</div>
      <div :style="{ color: color[8], fontWeight: 'bold' }">{{ accessCode }}</div>
    </Card>
    <Card :style="{ flexBasis: '40%', padding: '8px', marginLeft: '4px' }">
      <div :style="{ color: color[8] }">Password</div>
      <div :style="{ color: color[8], fontWeight: 'bold' }">{{ password }}</div>
    </Card>
  </div>
</template>

<script>
import _get from 'lodash/get'
import Card from '@/components/Card'

export default {
  components: {
    Card
  },
  props: {
    meetingLink: String,
    accessCode: String,
    password: String
  },
  data() {
    const theme = _get(this, ['$store', 'state', 'theme'], {})

    return {
      color: theme.color
    }
  }
}
</script>

export function data() {
  let DEFAULT_FORM_VALUES = {
    foreignAddress: false,
    address1: '',
    address2: '',
    address3: '',
    addressID: 0,
    city: '',
    region: '',
    postalCode: ''
  }

  let DEFAULT_FORM_ERRORS = {
    foreignAddress: '',
    address1: '',
    address2: '',
    address3: '',
    city: '',
    region: '',
    postalCode: ''
  }

  return {
    meetingType: 'Phone',
    types: ['Phone', 'Virtual', 'Physical'],

    title: 'Add',

    phone: {},
    virtual: {},
    physical: {},

    genericMeetingID: 0,

    note: '',

    toggle: false,

    loading: true,

    foreignAddress: false,

    formData: {
      ...DEFAULT_FORM_VALUES
    },

    formErrors: {
      ...DEFAULT_FORM_ERRORS
    }
  }
}

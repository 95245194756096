/*
	import { addVirtualMeeting } from '@/services/BoardMeetings/VirtualMeeting/Post';

  const { virtualMeeting } = await addVirtualMeeting ({
    json: {
			hoaID,
      
      meetingLink: "link.www",
      accessCode: "1",
      password: "0"
    }
  });
*/

/*
  exception.fields {
    : [ '' ],    
  }
*/

import kms from '@/services/kms'
import { notifyError } from '@/services/notify'

export async function addVirtualMeeting({ json }) {
  let exception = ''

  try {
    const result = await kms.post(`/BoardMeetings/VirtualMeeting`, json)

    return {
      successful: true,
      message: 'The virtual meeting was added successfully.',
      exception: null,
      phoneMeeting: result
    }
  } catch (_exception) {
    exception = _exception
  }

  let message = 'The virtual meeting could not be added.'
  notifyError(message)

  return {
    successful: false,
    message,
    exception,
    phoneMeeting: null
  }
}

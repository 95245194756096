/*
	import { addVirtualMeeting } from '@/services/BoardMeetings/PhysicalMeeting/Post';

  const { virtualMeeting } = await addPhysicalMeeting ({
    json: {
			hoaID,
      
      "addressID": 17551,
      "locationName": "Location Zero",
    }
  });
*/

import kms from '@/services/kms'
import { notifyError } from '@/services/notify'

export async function addPhysicalMeeting({ json }) {
  let exception = ''

  console.debug('addPhysicalMeeting=' + JSON.stringify(json))

  try {
    const result = await kms.post(`/BoardMeetings/PhysicalMeeting`, json)

    return {
      successful: true,
      message: 'The physical meeting was added successfully.',
      exception: null,
      phoneMeeting: result
    }
  } catch (_exception) {
    exception = _exception
  }

  let message = 'The physical meeting could not be added.'
  notifyError(message)

  return {
    successful: false,
    message,
    exception,
    phoneMeeting: null
  }
}

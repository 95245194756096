<template>
  <Modal
    :toggle.sync="toggle"
    :styles="{ modalContent: { minHeight: '100px' } }"
    :loading="loading"
  >
    <template v-slot:header>{{ title }} Location</template>

    <ValidationObserver ref="form" :style="{ padding: '1rem' }">
      <b-field label="Type">
        <b-select v-model="meetingType" :disabled="title === 'Add' ? false : true">
          <option v-for="t in types" :value="t" :key="t">
            {{ t }}
          </option>
        </b-select>
      </b-field>

      <div :style="{ height: '10px' }" />

      <div v-if="meetingType === 'Phone'">
        <valid-input
          v-model="phone.phoneNumber"
          vid="phoneNumber"
          type="input"
          label="Phone Number"
          name="Phone Number"
          placeholder="Phone Number"
          rules="required"
          :style="{
            minWidth: 'none !important'
          }"
        />

        <div :style="{ height: '20px' }" />

        <valid-input
          v-model="phone.accessCode"
          vid="accessCode"
          type="input"
          label="Access Code (optional)"
          name="Access Code"
          placeholder="Access Code"
          :style="{
            minWidth: 'none !important'
          }"
        />
      </div>

      <div v-if="meetingType === 'Virtual'">
        <valid-input
          v-model="virtual.meetingLink"
          vid="meetingLink"
          type="input"
          label="Meeting Link"
          name="Meeting Link"
          placeholder="Meeting Link"
          rules="required"
          :style="{
            minWidth: 'none !important'
          }"
        />

        <div :style="{ height: '20px' }" />

        <valid-input
          v-model="virtual.accessCode"
          vid="accessCode"
          type="input"
          label="Access Link"
          name="Access Link"
          placeholder="Access Link"
          rules="required"
          :style="{
            minWidth: 'none !important'
          }"
        />

        <div :style="{ height: '20px' }" />

        <valid-input
          v-model="virtual.password"
          vid="password"
          type="input"
          label="Password"
          name="Password"
          placeholder="Password"
          rules="required"
          :style="{
            minWidth: 'none !important'
          }"
        />
      </div>
      <div v-if="meetingType === 'Physical'">
        <valid-input
          v-model="physical.locationName"
          vid="locationName"
          type="input"
          label="Location Name"
          name="Location Name"
          placeholder="Location Name"
          rules="required"
          :style="{
            minWidth: 'none !important'
          }"
        />

        <div class="pt-5 pb-5">
          <b-checkbox
            v-model="formData.foreignAddress"
            @click.native="foreignAddress = !foreignAddress"
            class="has-left-text"
            aria-label="address is foreign"
            >Foreign</b-checkbox
          >
        </div>
        <div>
          <valid-input
            v-model="formData.address1"
            :message="formErrors.address1"
            rules="max:255"
            style="width:50px !important;"
            name="Address1"
            label="Address Line 1"
            maxlength="255"
            vid="Address1"
            placeholder="Address 1"
            spellcheck="true"
            aria-label="Address1"
            class="is-small"
          />
        </div>
        <div>
          <valid-input
            v-model="formData.address2"
            :message="formErrors.address2"
            style="width:50px !important;"
            name="Address2"
            label="Address Line 2"
            maxlength="255"
            vid="Address2"
            placeholder="Address 2"
            spellcheck="true"
            aria-label="Address2"
            rules="max:255"
            class="is-small"
          />
        </div>
        <div v-if="foreignAddress == true">
          <valid-input
            style="width:50px !important;"
            name="Address3"
            label="Address Line 3"
            maxlength="255"
            vid="Address3"
            placeholder="Address 3"
            spellcheck="true"
            aria-label="Address3"
            rules="max:255"
            class="is-small"
            v-model="formData.address3"
            :message="formErrors.address3"
          />
        </div>
        <div v-if="foreignAddress === false">
          <valid-input
            style="width:50px !important;"
            name="City"
            label="City"
            maxlength="100"
            vid="City"
            placeholder="City"
            spellcheck="true"
            aria-label="City"
            rules="max:100"
            class="is-small"
            v-model="formData.city"
            :message="formErrors.city"
          />
        </div>
        <div v-if="statesList && foreignAddress === false">
          <valid-select
            label="State"
            placeholder="States"
            vid="Region"
            v-model="formData.region"
            :message="formErrors.region"
          >
            <option v-for="option in statesList" :value="option.value" :key="option.value">{{
              option.label
            }}</option>
          </valid-select>
        </div>
        <div class="pt-5" v-if="foreignAddress === false">
          <valid-input
            style="width:50px !important;"
            name="PostalCode"
            label="Postal Code"
            maxlength="10"
            vid="PostalCode"
            placeholder="Postal Code"
            spellcheck="true"
            aria-label="PostalCode"
            rules="max:10"
            class="is-small"
            v-model="formData.postalCode"
            :message="formErrors.postalCode"
          />
        </div>
      </div>

      <div :style="{ marginTop: '1rem' }" />

      <valid-input
        v-model="note"
        vid="note"
        type="textarea"
        label="Note (optional)"
        name="Note (optional)"
        placeholder="Note"
        :style="{
          minWidth: 'none !important'
        }"
      />

      <div :style="{ marginTop: '1rem' }"></div>
    </ValidationObserver>

    <template v-slot:footer>
      <span v-if="title === 'Add'">
        <b-button @click="save" :style="{ float: 'right' }" type="is-primary">{{ title }}</b-button>
      </span>
      <span v-else-if="title === 'Update'">
        <b-button @click="saveupdate" :style="{ float: 'right' }" type="is-primary">{{
          title
        }}</b-button>
      </span>
      <b-button @click="toggle = false">Cancel</b-button>
    </template>
  </Modal>
</template>

<script>
import { computed } from './parts/computed'
import { data } from './parts/data'
import { methods } from './parts/methods'
import { mounted } from './parts/mounted'
import { props } from './parts/props'
import { watch } from './parts/watch'

import Modal from '@/components/Modal'
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'

import { ValidationObserver } from 'vee-validate'

export default {
  components: {
    Modal,
    ValidInput,
    ValidSelect,
    ValidationObserver
  },

  computed,
  data,
  methods,
  mounted,
  props,
  watch
}
</script>

/*
  example:
    import { getUnitList } from '@/services/Roster/Unit/List';

    const { list, exception } = await getUnitList ({
      hoaID
    });
    if (exception) {
      console.error (exception);
      return;
    }
*/
//
import _get from 'lodash/get'
//
import kms from '@/services/kms'
//
export async function getUnitList({ hoaID, kms: kms_ = kms } = {}) {
  try {
    const result = await kms_.get(`/Roster/Unit/List`, {
      params: {
        hoaID,
        includeOwnerContacts: false,
        startRecord: 1,
        recordCount: 99999
      }
    })

    let list = _get(result, 'results', [])
    if (!Array.isArray(list)) {
      list = []
    }

    console.debug('unit list=' + JSON.stringify(list))

    return {
      successful: true,
      list
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    list: [],
    successful: false
  }
}

///////

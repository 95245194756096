/*
	import { erasePhoneMeeting } from '@/services/BoardMeetings/PhoneMeeting/Delete';

  const { successful } = await erasePhoneMeeting ({
    phoneMeetingID
  });
*/

/*

*/

import kms from '@/services/kms'
import { notifyError } from '@/services/notify'

export async function erasePhoneMeeting({ phoneMeetingID }) {
  let exception = ''

  try {
    const result = await kms.delete(`/BoardMeetings/PhoneMeeting/${phoneMeetingID}`)

    return {
      successful: true,
      message: 'The phone meeting was deleted successfully.',
      exception: null,
      phoneMeeting: result
    }
  } catch (_exception) {
    exception = _exception
  }

  let message = 'A problem occurred and the phone meeting could not be deleted.'
  notifyError(message)

  return {
    successful: false,
    message,
    exception,
    phoneMeeting: null
  }
}

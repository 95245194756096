export function data() {
  return {
    loading: true,
    meetings: [],
    rows: [],
    columns: [
      {
        label: 'Meeting Type',
        field: 'meetingType'
      },
      {
        label: 'Details',
        field: 'details'
      },
      {
        label: '',
        field: 'erase'
      },
      {
        label: '',
        field: 'edit'
      }
    ]
  }
}
